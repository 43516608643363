<template>
  <div class="ele-body">
    <el-card shadow="never">
      <!-- 搜索表单 -->
      <div class="d-flexspabet">
        <div>
          <el-button @click="dialogFormVisibleAddTripartiteActivity = true"
            v-if="permission.includes('sys:tripartite_activity:add')" class="addbtn ">添加三方合作活动
          </el-button>
        </div>
        <el-form :model="table.where" class="ele-form-search d-flex" @keyup.enter.native="$refs.table.reload()"
          @submit.native.prevent>
          <el-form-item label="状态:" label-width="60px" class="w-200 mr-20">
            <el-select v-model="table.where.status" placeholder="请选择" clearable class="ele-fluid">
              <el-option value="close" label="手动关闭"></el-option>
              <el-option value="not" label="未开始"></el-option>
              <el-option value="done" label="已结束"></el-option>
              <el-option value="doing" label="进行中"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="省:" label-width="40px" class="w-150">
            <el-select v-model="table.where.pid" @change="handleChangeProv1(table.where.pid)" clearable>
              <el-option v-for="option in provArr" :label='option.name' :value="option.pid"
                :key="'fp' + option.pid"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="市:" label-width="40px" class="w-150">
            <el-select v-model="table.where.cid" @change="handleChangeCity1(table.where.cid), $forceUpdate()" clearable>
              <el-option v-for="option in cityArr" :label='option.name' :value="option.cid"
                :key="'fc' + option.cid"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="县:" label-width="40px" class="w-150">
            <el-select v-model="table.where.aid" @change="$forceUpdate()" clearable>
              <el-option v-for="option in districtArr" :label='option.name' :value="option.aid"
                :key="'fa' + option.aid"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
              class="ele-btn-icon search-margin-left">搜索
            </el-button>
            <el-button @click="(table.where = {}) && $refs.table.reload()">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- 数据表格 -->
      <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)" :stripe=true
        highlight-current-row>
        <template slot-scope="{index}">
          <el-table-column type="selection" width="45" align="center" fixed="left" />
          <el-table-column type="index" :index="index" label="序号" width="60" align="center" fixed="left"
            show-overflow-tooltip />
          <el-table-column prop="entry_pic" label="入口图片" >
            <template slot-scope="{row}">
              <el-image style="width:60px;height:60px;margin-right:10px" :src="row.entry_pic"  :preview-src-list="[row.entry_pic]"
                fit="cover" />

            </template>
          </el-table-column>
          <el-table-column prop="title" label="活动名称" show-overflow-tooltip min-width="120" />
          <el-table-column prop="address" label="省市区" show-overflow-tooltip min-width="120" />
          <el-table-column prop="status_text" label="活动状态" show-overflow-tooltip min-width="120" />
          <el-table-column prop="start_time" label="开始时间" show-overflow-tooltip min-width="140" />
          <el-table-column prop="end_time" label="结束时间" show-overflow-tooltip min-width="140" />
          <el-table-column prop="create_time" label="创建时间" show-overflow-tooltip min-width="140" />
          <el-table-column label="操作" width="200px" align="center" :resizable="false" fixed="right">
            <template slot-scope="{row}">
              <el-link @click="showActivity(row)" icon="el-icon-view" type="primary" :underline="false"
                v-if="permission.includes('sys:tripartite_activity:open')">查看
              </el-link>
              <span style="margin-left: 10px;">
                <el-link slot="reference" @click="closeActivity(row)" type="primary" icon="el-icon-open"
                  :underline="false" v-if="permission.includes('sys:tripartite_activity:close')">
                  手动结束
                </el-link>
              </span>
            </template>
          </el-table-column>
        </template>
      </ele-data-table>
      <!-- 对话框：添加 -->
      <el-dialog v-dialogDrag title="添加三方合作活动" :visible.sync="dialogFormVisibleAddTripartiteActivity" width="1500px"
        @closed="editForm = editFormDefault" :destroy-on-close="true" :lock-scroll="false">
        <div style="display: flex;text-align:justify;justify-content: space-between;background: white;height: 100%;">
          <el-card shadow="never">
            <el-form ref="editForm" v-model="editForm" label-width="180px" style="width:500px">
              <!-- 活动面向行政区划级别 -->
              <el-form-item label="活动行政区划级别：" prop="type">
                <el-select v-model="editForm.type" placeholder="请选择" clearable value-key="val">
                  <el-option v-for="option in typeOptions" :value="option.val" :key="option.val" :label="option.text" />
                </el-select>
              </el-form-item>
              <!-- 选择省市区 -->
              <el-form-item label="选择省：" v-if="pnameType && !cnameType">
                <el-select v-model="editForm.pid" @change="handleChangeProv" placeholder="请选择" auto-complete="off"
                  clearable filterable multiple>
                  <el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name" />
                </el-select>
              </el-form-item>
              <el-form-item label="选择省：" v-if="pnameType && cnameType">
                <el-select v-model="editForm.pid" @change="handleChangeProv" placeholder="请选择" auto-complete="off"
                  clearable filterable>
                  <el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name" />
                </el-select>
              </el-form-item>

              <el-form-item label="选择市：" v-if="cnameType && !anameType">
                <el-select v-model="editForm.cid" @change="handleChangeCity" placeholder="请选择" clearable filterable
                  multiple>
                  <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name" />
                </el-select>
              </el-form-item>
              <el-form-item label="选择市：" v-if="cnameType && anameType">
                <el-select v-model="editForm.cid" @change="handleChangeCity" placeholder="请选择" clearable filterable>
                  <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name" />
                </el-select>
              </el-form-item>

              <el-form-item label="选择区：" v-if="anameType">
                <el-select v-model="editForm.aid" placeholder="请选择" clearable filterable multiple>
                  <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid" :label="option.name" />
                </el-select>
              </el-form-item>
              <el-form-item label="活动标题：" prop="title">
                <el-input v-model="editForm.title" autocomplete="off" placeholder="请填写活动标题" class="input216" />
              </el-form-item>
              <el-form-item label="开始时间：" prop="start_time">
                <el-date-picker v-model="editForm.start_time" type="datetime" placeholder="请选择开始时间"
                  value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="结束时间：" prop="end_time">
                <el-date-picker v-model="editForm.end_time" type="datetime" placeholder="请选择结束时间"
                  value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="活动入口图片：" prop="entry_pic">
                <uploadImage style="width: 30%;float: left;" :limit="1" v-model="editForm.entry_pic" />
              </el-form-item>
            </el-form>
          </el-card>
          <div class="el-card is-never-shadow">
            <div style="font-size: 18px;padding-bottom: 20px;">活动详情介绍</div>
            <!-- 富文本编辑器 -->
            <tinymce-editor v-if="dialogFormVisibleAddTripartiteActivity" v-model="editForm.content" :init="editContent"
              key="adarheiuf" />
          </div>
        </div>
        <div slot="footer">
          <el-button @click="dialogFormVisibleAddReturnActivity = false">取消</el-button>
          <el-button type="primary" @click="saveActivity()">保存</el-button>
        </div>
      </el-dialog>
      <!-- 对话框：查看 -->
      <el-dialog v-dialogDrag title="三方合作活动详情" :visible.sync="dialogFormVisibleShowTripartiteActivity" width="1500px"
        :destroy-on-close="true" :lock-scroll="false">
        <div slot="footer">
          <div style="display: flex;text-align:justify;justify-content: space-between;background: white;height: 100%;">
            <el-card shadow="never" style="width: 400px">
              <div style="display: flex;text-align:justify;margin-bottom: 10px">
                <div style="width: 120px;"><el-tag>活动行政区划级别</el-tag></div>
                <div style="flex: 1"><el-tag type="success">{{ showData.type === 1 ? '省' : (showData.type === 2 ? '市' :
                  '区') }}</el-tag></div>
              </div>
              <div style="display: flex;text-align:justify;margin-bottom: 10px">
                <div style="width: 120px;"><el-tag>活动标题</el-tag></div>
                <div style="flex: 1"><el-tag type="info">{{ showData.title }}</el-tag></div>
              </div>
              <div style="display: flex;text-align:justify;margin-bottom: 10px">
                <div style="width: 120px;"><el-tag>开始时间</el-tag></div>
                <div style="flex: 1"><el-tag type="info">{{ showData.start_time }}</el-tag></div>
              </div>
              <div style="display: flex;text-align:justify;margin-bottom: 10px">
                <div style="width: 120px;"><el-tag>结束时间</el-tag></div>
                <div style="flex: 1"><el-tag type="info">{{ showData.end_time }}</el-tag></div>
              </div>
              <div style="display: flex;text-align:justify;margin-bottom: 10px">
                <div style="width: 120px;"><el-tag>入口图片</el-tag></div>
                <div style="flex: 1">
                  <el-image style="width: 100px; height: 100px" :src="showData.entry_pic"
                    :preview-src-list="[showData.entry_pic]" fit="fill" />
                </div>
              </div>
              <div style="display: flex;text-align:justify;margin-bottom: 10px">
                <div style="width: 120px;margin-bottom: 6px"><el-tag>活动地区</el-tag></div>
                <div style="flex: 1;">
                  <template v-if="showData.type >= 1">
                    <el-tag size="small" type="info">省</el-tag>
                    <p>{{ showData.areas.p.map(item => item.name).join('、') }}</p>
                  </template>
                  <template v-if="showData.type >= 2">
                    <el-tag size="small" type="info">市</el-tag>
                    <p>{{ showData.areas.c.map(item => item.name).join('、') }}</p>
                  </template>
                  <template v-if="showData.type >= 3">
                    <el-tag size="small" type="info">区</el-tag>
                    <p>{{ showData.areas.a.map(item => item.name).join('、') }}</p>
                  </template>
                </div>
              </div>
            </el-card>
            <div class="el-card is-never-shadow">
              <div style="font-size: 18px;padding-bottom: 20px;">活动详情介绍</div>
              <!-- 富文本编辑器 -->
              <tinymce-editor v-model="showData.content" :init="editContent" :key="showData.id" />
            </div>
          </div>
        </div>

      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import uploadImage from '@/components/uploadImage';
import TinymceEditor from '@/components/TinymceEditor'
import tinymce from "tinymce/tinymce";

export default {
  name: "SysUser",
  data() {
    return {
      table: {
        url: '/TripartiteActivity/activityList',
        where: {}
      },
      choose: [], // 表格选中数据
      provArr: [],//省列表
      cityArr: [],//市列表
      districtArr: [],//区列表
      dialogFormVisibleAddTripartiteActivity: false,
      dialogFormVisibleShowTripartiteActivity: false,
      showData: {},
      editForm: { type: 3, cid: '', pid: '', aid: [] },
      editFormDefault: { type: 3, cid: '', pid: '', aid: [] },
      typeOptions: [
        { val: 1, text: '省' },
        { val: 2, text: '市' },
        { val: 3, text: '区' },
      ],
      pnameType: true,//省输入框显示隐藏
      cnameType: true,//市输入框显示隐藏
      anameType: true,//区输入框显示隐藏
    }
  },
  watch: {
    // 监听选择角色
    'editForm.type'(val) {
      this.pnameType = false;
      this.cnameType = false;
      this.anameType = false;
      this.editForm.pid = '';
      this.editForm.cid = '';
      this.editForm.aid = '';

      this.$nextTick(() => {
        if (val === 1) {
          this.pnameType = true
          this.cnameType = false
          this.anameType = false
          this.editForm.pid = [];
          this.editForm.cid = '';
          this.editForm.aid = '';
        } else if (val === 2) {
          this.pnameType = true
          this.cnameType = true
          this.anameType = false
          this.editForm.pid = ''
          this.editForm.cid = []
          this.editForm.aid = ''
        } else {
          this.pnameType = true
          this.cnameType = true
          this.anameType = true
          this.editForm.pid = '';
          this.editForm.cid = '';
          this.editForm.aid = [];
        }
      }, 0)

    }
  },
  created() {
    this.$http.get('/common/province_list').then(res => {
      this.provArr = JSON.parse(res.data)
    })
  },

  computed: {
    ...mapGetters(["permission"]),
    editContent() {
      return {
        menubar: false,
        // file_picker_callback: this.file_picker_callback,
        skin_url: this.$store.state.theme.theme === 'dark' ? '/tinymce/skins/ui/oxide-dark' : '/tinymce/skins/ui/oxide',
        content_css: this.$store.state.theme.theme === 'dark' ? '/tinymce/skins/content/dark/content.css' : '/tinymce/skins/content/default/content.css'
      };
    },
  },
  components: { uploadImage, TinymceEditor },
  mounted() {
  },
  methods: {
    //弹窗选择省
    handleChangeProv(e) {
      if (this.cnameType) {
        /** 获取被选省份的pid**/
        let pid = ''
        this.provArr.forEach(function (item) {
          if (item.pid == e) {
            pid = item.pid
          }
        })

        this.editForm.pid = pid
        /** 根据被选省份的pid获取省市下面的市**/
        this.$http.post('/common/city_list', {
          pid: pid
        }).then(res => {
          let data = JSON.parse(res.data)
          this.cityArr = data
        })
      }

      this.editForm.cid = this.cnameType ? [] : ''
      this.editForm.aid = this.anameType ? [] : ''
      this.$forceUpdate();
    },
    //弹窗选择市
    handleChangeCity(e) {
      if (this.anameType) {
        if (e) {
          /** 获取被选市的cid**/
          let cid = ''
          this.cityArr.forEach(function (item) {
            if (item.cid == e) {
              cid = item.cid
            }
          })
          /** 根据被选市的cid获取市下面的县**/
          this.$http.post('/common/area_list', {
            cid: cid
          }).then(res => {
            let data = JSON.parse(res.data)
            this.districtArr = data
          })
        }
      }
      this.editForm.aid = '';
      this.$forceUpdate();
    },
    //筛选条件选择省
    handleChangeProv1(e) {
      /** 获取被选省份的pid**/
      let pid = ''
      this.provArr.forEach(function (item) {
        if (item.pid == e) {
          pid = item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list', {
        pid: pid
      }).then(res => {
        let data = JSON.parse(res.data)
        this.cityArr = data
        /** 选择省份清空市县**/
        this.editForm.cid = '';
        this.editForm.aid = '';
      })
    },
    //筛选条件选择市
    handleChangeCity1(e) {
      if (e) {
        /** 获取被选市的cid**/
        let cid = ''
        this.cityArr.forEach(function (item) {
          if (item.cid == e) {
            cid = item.cid
          }
        })
        /** 根据被选市的cid获取市下面的县**/
        this.$http.post('/common/area_list', {
          cid: cid
        }).then(res => {
          let data = JSON.parse(res.data)
          this.districtArr = data
          /** 选择省份清空县**/
          this.editForm.aid = '';
        })
      } else {
        this.table.where.pid = ''
        this.table.where.aid = ''
      }
    },

    /* 显示详情 */
    showActivity(row) {
      // this.showData = {content:''}
      // Object.assign(this.$data.showData, this.$options.data().showData)
      this.$http.post('/TripartiteActivity/activityShow', {
        id: row.id
      }).then(res => {

        let data = res.data
        if (data.code != 0) {
          this.$message.error(data.msg)
          return false;
        }
        this.showData = data.data
        this.showData.type = data.data.districts[0]['type'];
        setTimeout(() => {
          tinymce.activeEditor.setContent(this.showData.content, { format: 'raw' })
          //console.log(tinymce.activeEditor.getContent())
          tinymce.activeEditor.save()
        }, 1500)

        this.$forceUpdate()
        this.dialogFormVisibleShowTripartiteActivity = true;
      })
    },
    // 结束三方活动
    closeActivity(row) {
      this.$confirm('确定关闭活动吗?', '提示', {
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({
          lock: true
        });
        this.$http.post('/TripartiteActivity/activityClose', {
          id: row.id
        }).then(res => {
          loading.close();
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '关闭成功'
            });
            this.$refs.table.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(e => {
          loading.close();
          this.$message.error(e.message);
        });
      })
    },
    // 保存三方合作活动
    saveActivity() {
      //console.log(this.editForm)
      if (!this.editForm.title) {
        this.$message.error({
          message: '请输入标题'
        });
        return;
      }
      if (!this.editForm.content) {
        this.$message.error({
          message: '请输入活动内容'
        });
        return;
      }
      if (!this.editForm.entry_pic) {
        this.$message.error({
          message: '请上传活动入口图片'
        });
        return;
      }
      if (!this.editForm.start_time || !this.editForm.end_time) {
        this.$message.error({
          message: '请检查活动开始结束时间'
        });
        return;
      }
      if (!this.editForm.aid && !this.editForm.cid && !this.editForm.pid) {
        this.$message.error({
          message: '请选择参加活动的地区'
        });
        return;
      }
      this.$http.post('/TripartiteActivity/activityStore', this.editForm).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: res.data.msg,
            // duration:0,
            // showClose:true
          });
          this.$refs.table.reload();
          this.dialogFormVisibleAddTripartiteActivity = false
        } else {
          this.$message.error({
            message: res.data.msg,
            // duration:0,
            // showClose:true
          });
        }
      }).catch(e => {
        this.$message.error(e.message);
      });
    },
  }
}
</script>

<style scoped>
/* .edit /deep/.el-form-item__content {
  margin-left: 0 !important;
} */
/deep/ .tox-tinymce {
  height: 500px !important;
}

.ele-block .el-upload-dragger {
  width: 100%;
}

.changeStyle {
  width: 150px;
  margin-right: 30px;
}

.ele-body {
  padding: 15px;
}

.el-upload-list el-upload-list--text {
  display: none !important
}

/deep/ .el-tabs__item {
  padding: 0 20px !important;
  font-size: 15px;
}

/deep/ .el-tabs__nav-scroll {
  background: white !important;
  padding-top: 10px;
}

.boxTitle {
  padding: 8px
}

.orderImgBox {
  margin-left: 10px;
  height: 80px;
}

.orderImgBox span {
  font-size: 36px;
}

.user-info-tabs>>>.el-tabs__nav-wrap {
  padding-left: 20px;
}

.innerBox {
  width: 60%;
  margin: 0 auto;
  padding-top: 20px;
  border-bottom: 1px rgb(240, 240, 240) solid;
}

.innerBoxTo {
  width: 60%;
  margin: 0 auto;
  padding-top: 20px;
}

.innerTitle {
  font-size: 16px;
  margin-bottom: 20px;
  margin-left: 13px;
}

.inputWidth {
  width: 100%;
}

.templateBox p {
  padding: 4px 0 0 0;
}

/deep/ .el-dialog {
  margin-top: 60px !important;
}
</style>
